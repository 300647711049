<template>
<section class="wrapper bg-light">

	<hero2 v-for="(item, index) in property"
				 :key="'H2'+index"
				 :titre=item.name
				 :text="item.address+'<br/>'+item.zipcode+' '+item.neighborhood"
				 :img="'https://media.berlcoin.fr/property/'+item.path+'/1.jpg'"
				 :link_label1="$t('label_decouvrir')"
				 link_label2=""
				 :link1="'/'+$i18n.locale+'/View_property/'+item.path"
				 link2=""
				 :bt1="'<br/>'+$t('label_surface')+' : '+item.surface+'  m2'"
				 :bt2="'<br/>'+$t('label_garantie')+' : '+item.berlcoin+' blc'"
				 :bt3="'<br/>'+$t('label_financement')+' : '+  item.euros+'  €'"
				 :bt4="'<br/>'+$t('label_index')+' : '+item.capital_gain+'  %'"
				 bx1=""
				 bx2=""
				 bx3=""
				 bx4=""
				 :bi1="'https://media.berlcoin.fr/property/'+item.path+'/2.jpg'"
				 :bi2="'https://media.berlcoin.fr/property/'+item.path+'/3.jpg'"
				 :bi3="'https://media.berlcoin.fr/property/'+item.path+'/4.jpg'"
				 :bi4="'https://media.berlcoin.fr/property/'+item.path+'/5.jpg'" />




	<div class="container py-14 py-md-16"
			 style="display:none;">
		<h2 class="display-4 mb-3 text-center">Les biens immobiliers</h2>
		<p class="lead fs-lg mb-10 text-center px-md-16 px-lg-21 px-xl-0">Investissez en Berlcoin dans l'immobilier 3.0</p>

		<div class="row gy-6 gy-lg-0">
			<div class="col-md-4 col-lg-4"
					 v-for="(item, index) in property"
					 :key="index">
				<div class="item">
					<article>
						<figure class="overlay overlay1 hover-scale rounded mb-5"><a href="#"> <img src="@/assets/media/property/c17-2/1.jpg"
										 alt="" /></a>
							<figcaption>
								<h5 class="from-top mb-0">Détail</h5>
							</figcaption>
						</figure>
						<div class="post-header">
							<div class="post-category text-line">
								<a href="#"
									 class="hover"
									 rel="category">{{item.zipcode}} {{item.neighborhood}}</a>
							</div>
							<!-- /.post-category -->
							<h2 class="post-title h3 mt-1 mb-3"><a class="link-dark"
									 href="@/blog-post.html">{{item.name}}</a></h2>
						</div>
						<!-- /.post-header -->
						<div class="post-footer">
							<ul class="post-meta">
								<li class="post-date"><i class="uil uil-calendar-alt"></i><span>14 Apr 2021</span></li>
								<li class="post-comments"><a href="#"><i class="uil uil-comment"></i>4</a></li>
							</ul>
							<!-- /.post-meta -->
						</div>
						<!-- /.post-footer -->
					</article>
					<!-- /article -->
				</div>
			</div>
			<!-- /.container -->
		</div>
	</div>
</section>
<!-- /section -->
</template>


<script>
import hero2 from "@/components/hero2.vue";

export default {
	name: 'Property',
	components: {
		hero2
	},
	props: {},
	data: () => ({
		opt: [],
		axios_private: [],
		property: [],
	}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {
		if (this.$route.params.lang != undefined) this.$i18n.locale = this.$route.params.lang

		this.opt = [];
		this.opt["api"] = "property"
		this.opt["selection"] = 'berlcoin';
		this.charge_private();
	},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {
		charge_private() {
			this.$store.dispatch('post', this.opt).then(response => {
				if (response != undefined) this.axios_private = response.data
			})
		},

	},
	watch: {
		axios_private: function () {
			if (this.axios_private != undefined) {
				this.property = this.axios_private.data;
				console.log(this.axios_private)
			}
		}

	}
}
</script>

<style scoped>
</style>
